$icon-font-path: "~bootstrap-sass/assets/fonts/bootstrap/";

@import "~bootstrap-sass/assets/stylesheets/bootstrap";

@import "./sass/variable";
@mixin linear-gradient($color-stops, $start: top, $image: false) {
  @include background-image($image, linear-gradient($start, $color-stops));
}
.inline-block {
  display: inline-block;
}
.block {
  display: block;
  clear: both;
}
.vert-s-5 {
  margin: 5px 0;
}
.browsehappy {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0;
}

.alert-box {
  z-index: 1000;
  position: fixed;
  left: 1%;
  top: 1%;
  width: 98%;
}
.clear {
  clear: both;
}
.green-background {
  background: $green;
}

.no-wrap {
  white-space: nowrap;
}

.white-text {
  color: white;
}

.full-size {
  width: 100%;
  height: 100% !important;
}

.margin-none {
  margin: 0;
}

.vert-spacing {
  margin: 10px 0;
}

.scroll-x {
  overflow-x: auto;
}

.scroll-y {
  overflow-y: auto;
}
body {
  background-color: #ffffff;
  color: #000000;
  font-family: Arial, sans-serif;
  font-size: 12px;
}

hr {
  margin: 0;
  margin-top: 3px;
  margin-bottom: 3px;
}

h5 {
  font-weight: bold;
}

input.form-control,
input {
  background-color: $lightgrey;
}

.header {
  label {
    padding-top: 7px;
    margin-left: 10px;
    color: #dddddd;
  }

  a {
    color: #dddddd;
  }

  button {
    /*margin-left: 4px;*/
  }

  height: 66px;
  background: #005902;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  padding-right: 10px;
}

$footer-height: 24px;
$case-list-panel-height: 250px;
$supervision-panel-width: 470px;
$panel-spacing: 4px;
$spliter-size: $panel-spacing;

.content {
  background: #c3a342;
  position: absolute;
  top: $headerHeight;
  bottom: 0px;
  left: 0px;
  right: 0px;
  // min-width: $minWidth;
}

.footer {
  height: $footer-height;
  background: #005902;
  position: absolute;
  left: 0px;
  bottom: 0px;
  width: 100%;
  color: white;
  z-index: 1000;
  // min-width: $minWidth;
}

.case-list-panel {
  background: white;
  position: absolute;
  top: $panel-spacing;
  left: $panel-spacing;
  right: $panel-spacing;
  bottom: 0;
  margin: 0px;
}

$bottom-content-height: 500px;

#top-content {
  position: absolute;
  top: 0;
  bottom: $bottom-content-height + $spliter-size;
  left: 0;
  right: 0;
  background-color: $poop;
  overflow: hidden;
}

#bottom-content {
  position: absolute;
  height: $bottom-content-height;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: auto;
  background-color: $poop;
}

#top-content.has-effect,
#bottom-content.has-effect {
  transition: all 0.5s;
}

#bottom-left-content {
  background-color: $poop;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 480px;
  overflow: none;
}

#bottom-right-content {
  background-color: $poop;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 483px;
  right: $panel-spacing;
  overflow: none;
  // min-width:530px;
  // div.scrollableContainer {
  //   overflow-x: auto;
  //   th, td {
  //     padding: 1px 5px;
  //   }
  // }
}

.customScrollableContainer {
  overflow-x: auto;
  overflow-y: hidden;
  position: relative;
  padding-top: 20px;
}

.customScrollArea {
  overflow-y: auto;
  overflow-x: hidden;
  table,
  table.table {
    thead {
      tr {
        position: absolute;
        top: 0;
        th {
          border-bottom: none;
        }
      }
    }
    td,
    th {
      padding: 2px 0;
      padding-left: 5px;
    }
  }
}

#vertical-resizer {
  background-color: $poop;
  position: absolute;
  top: 0px;
  bottom: 0;
  left: 480px;
  width: $spliter-size;
  cursor: ew-resize;
}

#horizontal-resizer {
  background-color: $poop;
  position: absolute;
  height: $spliter-size;
  bottom: $bottom-content-height;
  left: 0;
  right: 0;
  cursor: ns-resize;
}

#adminConfig {
  #top-content {
    bottom: 110px;
  }

  #bottom-content {
    height: 110px;
    .panel-heading {
      padding: 0 10px;
      height: 40px;
      .col-6 {
        height: 40px;
        &:last-child {
          border-left: 6px solid $poop;
          padding-left: 10px;
        }
        .glyphicon {
          top: 10px;
        }
      }
      h3 {
        line-height: 30px;
        height: 30px;
      }
    }
  }
}

body > ul.dropdown-menu.ng-valid-date {
  margin-top: -205px;
}

.case-supervision-panel {
  background: white;
  position: absolute;
  top: 0;
  left: $panel-spacing;
  right: 0;
  bottom: $panel-spacing;
}

.market-data-panel {
  background: white;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: $panel-spacing;
}

.maximized-panel {
  position: absolute;
  top: $panel-spacing;
  left: $panel-spacing;
  right: $panel-spacing;
  bottom: $panel-spacing;
  height: auto;
  width: auto;
  z-index: 100;
}
.case-list-grid {
  /*border: 0;*/
  width: 100%;
}

.tr-even {
  background-color: $lightPoop;
}
.tr-odd {
  background-color: rgb(255, 255, 255);
}

.tr-selected {
  background-color: rgba($oldGreen, 0.3) !important;
}

.green li a {
  background-color: $lightPoop !important;
  &:hover {
    background-color: rgba($oldGreen, 0.3) !important;
  }
}

.grid-cell {
  text-align: left;
}

.grid-cell-right {
  text-align: right;
}

.table-bold-header {
  th {
    font-weight: bold;
    text-align: center;
  }
}

td.res-col {
  width: 10vw;
  min-width: 85px;
  max-width: 135px;
}
.td-right {
  text-align: right;
  vertical-align: top;
  padding-right: 3px;
  padding-top: 1px;
  padding-bottom: 1px;
}
.td-left {
  text-align: left;
  vertical-align: top;
  padding-right: 3px;
  padding-top: 1px;
  padding-bottom: 1px;
}

.td-center {
  text-align: center;
  vertical-align: top;
  padding-right: 3px;
  padding-top: 1px;
  padding-bottom: 1px;
}

.td-shaded {
  background-color: #e5e5e5;
}

.td-label {
  line-height: 22px !important;
  padding-left: 15px !important;
  padding-right: 5px !important;
}
//need to set the initial size of the case grid for the scroll
//subsequent maximized/minized sizes set from the script
.caseGrid {
  th,
  tbody tr {
    cursor: pointer;
  }
  th {
    span.glyphicon {
      padding-top: 6px;
    }
  }
}

/*#customerTradeSummary {
  .scrollableContainer {
    height: 80px;
  }
}*/

//table crap
.table > thead > tr > th {
  padding: 0;
  line-height: 1.42857143;
  vertical-align: top;
  border-top: 1px solid #ddd;
}

.table > thead > tr > th {
  vertical-align: bottom;
  border-bottom: 1px solid #ddd;
}

.table > tbody + tbody {
  border-top: 1px solid #ddd;
}

.scrollableContainer {
  height: 300px;
  position: relative;
  padding-top: 26px;
  margin-bottom: 30px;

  .headerSpacer {
    border: 1px solid #d5d5d5;
    border-bottom-color: #bbb;
    position: absolute;
    height: 26px;
    top: 0;
    right: 0;
    left: 0;
    border-left: none;
    border-right: none;
  }
  th .th-inner .title > span {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  th .orderWrapper {
    position: absolute;
    top: 0;
    right: 2px;
    cursor: pointer;
  }
}

.ellipsis-overflow {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.scrollArea {
  &:not(.autoHeight) {
    height: 100%;
  }
  overflow-x: hidden;
  overflow-y: auto;
  border-top: none;
  // border-left: 1px solid #d5d5d5;
  // border-right: 1px solid #d5d5d5;
  // border-bottom: 1px solid #d5d5d5;

  table {
    overflow-x: hidden;
    overflow-y: auto;
    margin-bottom: 0;
    border: none;
    border-collapse: separate;
  }

  table th {
    padding: 0;
    border: none;
  }

  table .th-inner {
    position: absolute;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    top: 0;
    height: 26px;
    line-height: 26px;
    padding: 0 1px;
    border-left: 1px solid #ddd;
  }

  table tr td {
    border-left: 1px solid #ddd;
    padding: 1px 3px;
  }

  table tr th:first-child .th-inner {
    border-left: none;
  }

  table .th-inner.condensed {
    padding: 0 1px;
  }
  table tbody tr td:first-child {
    border-left: none;
  }
  table tbody tr td:last-child {
    border-right: none;
  }
  table tbody tr:first-child td {
    border-top: none;
  }

  table tbody tr:last-child td {
    border-bottom: 1px solid #ddd;
  }
}

table .cr {
  min-width: 30px;
}

.scroll-overflow-y-panel-body {
  overflow-y: auto;
  width: 100%;
  top: 33px;
  bottom: 0px;
  position: absolute;
}

//panel-* styles used in accordion
.panel-body {
  margin: 0px;
  padding: 0px;
}

.panel-title {
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 14px;
  font-weight: bold;
  color: inherit;
}

.panel-default > .panel-heading {
  color: #333;
  /*background-color: lighten($green, 0%);*/
  border-color: #ddd;
}

.panel-heading {
  padding: 5px 5px;
  border-bottom: 1px solid transparent;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  button {
    height: 20px;
    line-height: 10px;
    padding: 4px 10px;
  }
}

.bordered {
  border: 1px solid black;
  padding-top: 5px;
}

.text-bold {
  font-weight: bold;
}

.text-lighter {
  font-weight: lighter;
}

.modal-header {
  height: 30px;
  padding-left: 10;
  padding-right: 10;
  padding-top: 0;
  padding-bottom: 0;
  color: white;
  border-bottom: 1px solid white;
}
.modal-content {
  width: 620px;
  margin-left: -0px;

  background: $green;
  label {
    color: white;
  }
  .dropdown-menu {
    background: $green;
    border-color: white;
    color: white;
  }

  /*	input {
      margin-right: 10px;
    }
    select {
      margin-right: 10px;
    }*/
  hr {
    border-color: $lightgrey;
  }
  h5 {
    color: white;
  }
  td {
    padding-left: 10px;
  }
}

.gold-frame .modal-content {
  border: 2px solid $poop;
}

/* tab color */
.nav-tabs > li > a {
  background-color: lighten($green, 5%);
  /*border-color: #777777;*/
  color: #ddd;
  cursor: pointer;
  padding: 0;
  padding-left: 4px;
  padding-right: 4px;
  padding-top: 2px;
  padding-bottom: 2px;
}

/* active tab color */
.nav-tabs > li.active > a,
.nav-tabs > li.active > a:hover,
.nav-tabs > li.active > a:focus {
  color: #fff;
  background-color: $green;
  /*border: 1px solid #888888;*/
}

/* hover tab color */
.nav-tabs > li > a:hover {
  color: #fff;
  background-color: lighten($green, 7%);
}

/*.form-control input[type="text"] {
  height: 14px;
  font-size: 10px;
  line-height: 14px;
}*/

/*.form-control {
  height: 20px;
  font-size: 12px;
  line-height: 18px;
  padding: 0;
}*/

.form-control {
  height: 20px;
  font-size: 12px;
  line-height: 100%;
  padding: 0;
  padding-top: 1px;
}

/*select {
  .form-control {
    padding-top: 10px;
  }
}*/

.btn-sm {
  padding: 3px;
}

.btn {
  background: lighten($green, 5%);
  border-color: white;
  color: white;
  &:active {
    background: darken($green, 35%);
    color: white;
  }
  &:hover {
    background: lighten($green, 5%);
    color: white;
  }
  &:focus {
    background: lighten($green, 5%);
    color: white;
  }
  &.green {
    background: lighten($green, 5%) !important;
    color: white !important;
    border-color: white !important;
    .caret {
      border-top-color: white;
    }
  }
}

.btn-default {
  background: $lighterGreen;
  border-color: white;
}

.btn-default,
.btn-green {
  &:active {
    border-color: white;
    background: darken($poop, 5%);
    color: white;
  }
  &:hover {
    border-color: white;
    background: $poop;
    color: white;
  }
  &:focus {
    border-color: white;
    background: $poop;
    color: white;
  }
  &:active:hover,
  &:active:focus {
    background: #c3a342;
    border-color: white;
    color: white;
  }
}

.dropdown-menu {
  background: $green;
  border-color: white;

  color: white;
  .btn {
    border: 0;
  }
  .btn-default {
    background: $green;
    border-color: white;
    &:active {
      border-color: white;
      background: darken($poop, 5%);
      color: white;
    }
    &:hover {
      border-color: white;
      background: $poop;
      color: white;
    }
    &:focus {
      border-color: white;
      background: $poop;
      color: white;
    }
  }
}

textarea:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
.uneditable-input:focus {
  /*border-color: rgba(126, 239, 104, 0.8);*/
  /*box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px rgba(195, 163, 66, 0.6);*/
  /*outline: 0 none;*/
}

@mixin animate($name, $duration, $iteration) {
  /* Chrome, Safari, Opera */
  -webkit-animation-name: $name;
  -webkit-animation-duration: $duration;
  -webkit-animation-timing-function: linear;
  -webkit-animation-iteration-count: $iteration;
  /* Standard syntax */
  animation-name: $name;
  animation-duration: $duration;
  animation-timing-function: linear;
  animation-iteration-count: $iteration;
}
@-webkit-keyframes flashError {
  from {
    border-color: #cccccc;
  }
  to {
    border-color: #b94a48;
  }
}
@keyframes flashError {
  from {
    border-color: #cccccc;
  }
  to {
    border-color: #b94a48;
  }
}

.has-error {
  @include animate(flashError, 1s, 5);
  border-color: #b94a48;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
label.has-error {
  border-width: 1px;
  border-style: solid;
  border-radius: 5px;
  padding-left: 5px;
  padding-right: 5px;
}

.red-text {
  color: #b94a48;
}

@mixin h-linear-gradient(
  $leftColor,
  $rightColor,
  $rightMostColor: $leftColor,
  $direction: auto
) {
  @if $direction == auto {
    background: -webkit-linear-gradient(
      left,
      $leftColor,
      $rightColor
    ); /* For Safari 5.1 to 6.0 */
    background: -o-linear-gradient(
      right,
      $leftColor,
      $rightColor
    ); /* For Opera 11.1 to 12.0 */
    background: -moz-linear-gradient(
      right,
      $leftColor,
      $rightColor
    ); /* For Firefox 3.6 to 15 */
    background: linear-gradient(
      to right,
      $leftColor,
      $rightColor
    ); /* Standard syntax */
  } @else {
    background: -webkit-linear-gradient(
      $leftColor,
      $rightColor,
      $rightMostColor
    ); /* For Safari 5.1 to 6.0 */
    background: -o-linear-gradient(
      $leftColor,
      $rightColor,
      $rightMostColor
    ); /* For Opera 11.1 to 12.0 */
    background: -moz-linear-gradient(
      $leftColor,
      $rightColor,
      $rightMostColor
    ); /* For Firefox 3.6 to 15 */
    background: linear-gradient(
      $leftColor,
      $rightColor,
      $rightMostColor
    ); /* Standard syntax */
  }
}

@mixin v-linear-gradient($topColor, $bottomColor) {
  background: -webkit-linear-gradient(
    $topColor,
    $bottomColor
  ); /* For Safari 5.1 to 6.0 */
  background: -o-linear-gradient(
    $topColor,
    $bottomColor
  ); /* For Opera 11.1 to 12.0 */
  background: -moz-linear-gradient(
    $topColor,
    $bottomColor
  ); /* For Firefox 3.6 to 15 */
  background: linear-gradient($topColor, $bottomColor); /* Standard syntax */
}

@mixin radial-gradient($interiorColor, $exteriorColor) {
  background: -webkit-radial-gradient(
    $exteriorColor,
    $interiorColor
  ); /* Safari 5.1 to 6.0 */
  background: -o-radial-gradient(
    $exteriorColor,
    $interiorColor
  ); /* For Opera 11.6 to 12.0 */
  background: -moz-radial-gradient(
    $exteriorColor,
    $interiorColor
  ); /* For Firefox 3.6 to 15 */
  background: radial-gradient(
    $exteriorColor,
    $interiorColor
  ); /* Standard syntax */
}

@mixin green-radial-gradient {
  @include radial-gradient($green, lighten($green, 10%));
}
@mixin fade($leftColor, $rightColor) {
  @include h-linear-gradient($leftColor, $rightColor);
  height: 1px;
  display: block;
  width: 100%;
}

.faded {
  @include fade(rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
}

.green-faded {
  @include fade(rgba($green, 1), rgba($green, 0));
  margin-bottom: 10px;
}

@mixin clear-after {
  content: " ";
  visibility: hidden;
  display: block;
  height: 0;
  clear: both;
}
@mixin padding-after {
  content: "";
  padding-right: 20px;
  height: 0;
}

.tkg-header {
  position: relative;
  height: $headerHeight;
  min-width: $minWidth;
  background: $green;
  &:after {
    @include clear-after;
  }
  * {
    color: white;
    text-align: center;
  }
  button,
  input {
    color: black;
  }
  .header-table {
    height: 60px;
    float: left;
    margin-left: 15px;
    margin-top: 4px;
    // outline: none;
  }
  tr {
    line-height: 20px;
    height: 20px;
  }
  td {
    // padding-left: 10px;
    text-align: right;
    & > *:not(ul) {
      line-height: 20px;
      height: 20px;
      width: 100%;
    }
    &.res-col {
      width: 10vw;
      min-width: 85px;
      max-width: 135px;
    }
    button:not(.btn-sm) {
      // padding: 1px;
      line-height: 17px;
      color: #dddddd;
      width: 48%;
      text-overflow: ellipsis;
      overflow: hidden;
      padding: 1px 5px;
    }
    button.btn-block {
      width: 100%;
    }
  }
  a {
    color: #dddddd;
  }
  .dropdown-menu td {
    padding: 1px;
  }

  .dropdown-menu li {
    padding: 0 5px;
  }
}

.header-login {
  position: absolute;
  float: right;
  top: 0;
  right: 0;
  padding: 5px;
}
.surveillance-btn {
  position: absolute;
  right: 5px;
  top: 40px;
  min-width: 140px;
  display: flex;
  align-items: center;
  gap: 6px;
  button {
    height: 20px;
    line-height: 7px;
  }
  .dropdown-toggle ~ .dropdown-menu {
    top: 20px;
  }
  // height: 20px;
}
@media screen and (max-width: $minWidth) {
  .hide-min-width,
  body:not(.Index):not(.Splits):not(.Omnibus) .header-login {
    display: none;
  }
}
@media screen and (min-width: $minWidth - 1px) {
  .show-min-width {
    display: none;
  }
}
.tkg-banner {
  float: left;
  h1,
  h3 {
    color: white;
    float: left;
    // text-shadow: 1px 1px 5px darken(white, 10%);
  }
  h1 {
    font-size: 60px;
    margin: 0;
    // margin-right: 10px;
    padding: 5px;
    padding-top: 0;
    margin-top: 4px;
  }
  h3 {
    margin: 0;
    font-size: 30px;
    font-weight: 300;
  }

  .tkg-secondary-header {
    padding: 5px;
    line-height: 76px;
    min-height: 71px;
    width: 235px;
    & > div {
      display: block;
      clear: right;
    }
    .faded {
      margin-top: 33px;
      margin-left: 140px;
      width: 100px;
      position: absolute;
    }
  }
}

.table-wrapper,
#customerTradeDataTabset,
#adminConfig {
  tbody {
    tr:nth-child(even):not(.tr-odd) {
      @extend .tr-even;
    }
    tr:nth-child(odd):not(.tr-even) {
      @extend .tr-odd;
    }
  }
}

#customerTradeDataTabset {
  tbody tr {
    cursor: pointer;
  }
}

.tab-bar {
  height: 25px;
  line-height: 25px;
  position: absolute;
  width: 100%;
}

.tabs {
  cursor: pointer;
  color: white;
  height: 100%;
  display: inline-block;
  text-align: center;
  background-color: $green;
  border: 2px solid lighten($green, 5%);
  float: left;
  &.selected {
    background: lighten($green, 10%);
  }

  &:hover:not(.selected) {
    background: lighten($green, 5%);
  }
}

$i: 1;
@while $i < 13 {
  .col-#{$i} {
    width: percentage($i/12);
    display: inline-block;
    $i: $i + 1;
  }
}

.tkg-form {
  select {
    font-size: 12px;
    min-height: 20px;
    height: 20px;
    &.has-error {
      font-size: 14px;
    }
  }
  textarea {
    width: 90%;
    margin-left: 10px;
    height: 55px;
  }
}

.config-form {
  input[type="text"] {
    max-width: 105px;
  }
}

// .modal-rule-table {
//   button, input[type='number'] {
//     width: 50% !important;
//   }
//   button {
//     float: left;
//     white-space: nowrap;
//     overflow: hidden;
//     text-overflow: ellipsis;
//   }
// }

.rule-table {
  tbody {
    display: block;
    tr {
      background-color: white !important;
    }
  }
  .td-label {
    text-align: right;
  }
  button[type="submit"] {
    width: 100px;
  }
}

.dropdown-toggle {
  width: 100% !important;
  background: white;
  font-size: 12px;
  color: black !important;
  @include v-linear-gradient(white, #efefef);
  &:hover,
  &:focus {
    @include v-linear-gradient(white, #efefef);
  }
  & ~ .dropdown-menu {
    top: inherit;
    left: inherit;
    background: white;
    padding: 1px 3px;
    font-size: 12px;
    margin: 0;
    padding: 0;
    min-width: 140px;
    li > a {
      padding: 3px 5px;
      text-align: left;
      padding-left: 10px;
    }
    & .glyphicon-ok {
      color: black;
      padding-right: 20px;
    }
  }
}

.modal-content .dropdown-toggle ~ .dropdown-menu {
  min-width: 80px;
}

tr .dropdown-toggle {
  height: 20px;
  line-height: 5px;
  vertical-align: middle;
}

.open .btn-default.dropdown-toggle {
  @include v-linear-gradient(#efefef, white);
}

#modalScoreBar {
  tr {
    td:nth-child(1) {
      min-width: 175px;
      color: white;
      padding-right: 20px;
      text-align: right;
    }
    td:nth-child(2) {
      width: 100%;
    }
  }
  td div:nth-child(1) {
    margin-bottom: 10px;
  }
  .progress-bar {
    text-align: right;
    & b:not(.no-padding) {
      color: black;
      margin-right: 10px;
    }
  }
}

.progress-bar-gold {
  background-color: $poop;
}

.tkg-warn-tab {
  cursor: default !important;
  background-color: white !important;
  color: #999999 !important;
  border: 1px solid $poop !important;
  & > a {
    cursor: default !important;
    background-color: white !important;
    color: #999999 !important;
    border: none !important;
  }
}

.error {
  font-size: 12px;
  color: red;
  font-weight: normal;
  margin-left: 10px;
}

.invisible {
  visibility: hidden;
}

.status-row {
  & > td {
    padding-top: 5px;
  }
  input[type="radio"] {
    margin: 0;
    margin-right: 5px;
  }
}

.input-group-addon {
  padding-top: 0;
  padding-bottom: 0;
}

@import "sass/module";

/* Make clicks pass-through */
#loading-bar,
#loading-bar-spinner {
  pointer-events: none;
  -webkit-pointer-events: none;
  -webkit-transition: 350ms linear all;
  -moz-transition: 350ms linear all;
  -o-transition: 350ms linear all;
  transition: 350ms linear all;
}

#loading-bar.ng-enter,
#loading-bar.ng-leave.ng-leave-active,
#loading-bar-spinner.ng-enter,
#loading-bar-spinner.ng-leave.ng-leave-active {
  opacity: 0;
}

#loading-bar.ng-enter.ng-enter-active,
#loading-bar.ng-leave,
#loading-bar-spinner.ng-enter.ng-enter-active,
#loading-bar-spinner.ng-leave {
  opacity: 1;
}

#loading-bar .bar {
  -webkit-transition: width 350ms;
  -moz-transition: width 350ms;
  -o-transition: width 350ms;
  transition: width 350ms;

  background: #c3a342;
  position: fixed;
  z-index: 2000;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 12px;
}

/* Fancy blur effect */
/*#loading-bar .peg {
  display: block;
  position: absolute;
  right: 0px;
  width: 100px;
  height: 100%;
  box-shadow: 0 0 10px #C3A342, 0 0 5px #C3A342;
  opacity: 1.0;

  -webkit-transform: rotate(3deg) translate(0px, -4px);
  -moz-transform: rotate(3deg) translate(0px, -4px);
  -ms-transform: rotate(3deg) translate(0px, -4px);
  -o-transform: rotate(3deg) translate(0px, -4px);
  transform: rotate(3deg) translate(0px, -4px);
}*/

#loading-bar-spinner {
  display: block;
  position: fixed;
  z-index: 100;
  top: 6px;
  left: 50px;
}

#loading-bar-spinner .spinner-icon {
  width: 48px;
  height: 48px;

  border: solid 8px transparent;
  border-top-color: #c3a342;
  border-left-color: #c3a342;
  border-radius: 30px;

  -webkit-animation: loading-bar-spinner 400ms linear infinite;
  -moz-animation: loading-bar-spinner 400ms linear infinite;
  -ms-animation: loading-bar-spinner 400ms linear infinite;
  -o-animation: loading-bar-spinner 400ms linear infinite;
  animation: loading-bar-spinner 400ms linear infinite;
}

@-webkit-keyframes loading-bar-spinner {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-moz-keyframes loading-bar-spinner {
  0% {
    -moz-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -moz-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-o-keyframes loading-bar-spinner {
  0% {
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-ms-keyframes loading-bar-spinner {
  0% {
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes loading-bar-spinner {
  0% {
    transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
