$black: #333333;
$oldGreen: #005902;
$green: #4f684a;
$lighterGreen: #4d9758;
// $poop: #C3A342;
$poop: #d5cd92;
$lightPoop: lighten($poop, 20%);
$borderRadius: 4px;
$lightgrey: #efefef;
$minWidth: 1080px;
$headerHeight: 70px;
$disabledColor: #B9B9B9;
