#market-data {
  .tkg-header {
    min-width: inherit;
  }
  @media screen and (max-width: $minWidth) {
    .header-login {
      display: inherit !important;
    }
  }
}
