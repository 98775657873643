$table-border-color: #ddd !default !global;
$scrollable-cell-height: 20px !global;
$ib: inline-block !global;

$green: #005902 !global;
$poop: #C3A342 !global;
$lightgrey: #efefef !global;

@mixin no-select() {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@mixin pretty-textoverflow() {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin linear-grad ($startColor, $endColor, $direction: top) {
  $endDirection: $direction;
  @if $direction == top {
    $endDirection: bottom;
  } @else if $direction == bottom {
    $endDirection: top;
  } @else if $direction == left {
    $endDirection: right;
  } @else {
    $endDirection: left;
  }
  background: -webkit-linear-gradient($direction, $startColor , $endColor); /* For Safari 5.1 to 6.0 */
  background: -o-linear-gradient($endDirection, $startColor, $endColor); /* For Opera 11.1 to 12.0 */
  background: -moz-linear-gradient($endDirection, $startColor, $endColor); /* For Firefox 3.6 to 15 */
  background: linear-gradient(to $endDirection, $startColor , $endColor); /* Standard syntax */
}

@mixin golden-border () {
  border: 6px solid $poop;
}

@mixin blink-border ($colorStart: $tabble-border-color, $colorStop: darken($table-border-color, 10%), $width: 1px, $side : all) {
  @if $side == all {
    from {border: $width solid $colorStart}
    to {border: $width solid $colorStop}
  } @else {
    from {border-#{$side}: $width solid $colorStart}
    to {border-#{$side}: $width solid $colorStop}
  }
}


.scrollable-table {
  // thead {
  //   @include linear-grad(lighten($lightgrey, 20%), darken($lightgrey, 0%));
  // }
  &.caseGrid {
    span.glyphicon {
      padding-top: 4px;
    }
  }
  tr:nth-child(even) {
    background-color: lighten($poop, 40%);
  }

  border: none;
  thead, tbody {
    position: absolute;
  }
  thead {
    overflow: hidden;
    border: 2px solid $table-border-color;
    tr {
      position: relative;
      display: block;
    }
  }
  tbody {
    overflow: auto;
    border: 2px solid $table-border-color;
    border-top: none;
  }
  td, th {
    padding: 1px 3px !important;
    // padding: 0 !important;
    line-height: 1.9 !important;
    & > div:first-child {
      @include pretty-textoverflow;
      height: $scrollable-cell-height;
      float: left;
      display: $ib;
      position: relative;
      // z-index: 2;
    }
  }

  th {
    cursor: pointer;
  }

}

.adminOmnibus, .adminSymbol {
  .scrollableContainer {
    margin-bottom: 0;
  }
}
