.modal-body {
  input[type="checkbox"] {
    margin-top: -1px;
    margin-left: 5px;
  }
}

.tooltip.top {
  .tooltip-arrow {
    border-top-color: $poop;
  }
}

.tooltip-inner {
  background: $lightPoop;
  border: 1px solid $poop;
  border-radius: $borderRadius;
  box-shadow: 0 0 2px 1px $poop;
  opacity: 1;
  color: $black;
}

input[type="radio"].radio-inline {
  margin-top: 0;
  margin-left: 5px;
}
