#password-meter {
  width: 100%;
  margin-bottom: 15px;
  // height: 18px;
  position: relative;
  display: block;
  // .level-0 {
  //   background: linear-gradient(to right, red, darkorange);
  // }
  // .level-1 {
  //   background: linear-gradient(to right, darkorange, #ffb55a);
  // }
  // .level-2 {
  //   background: linear-gradient(to right, #ffb55a, #CCCB1E);
  // }
  // .level-3 {
  //   background: linear-gradient(to right, #CCCB1E, lightgreen);
  // }
  // .level-4 {
  //   background: linear-gradient(to right, lightgreen, green);
  // }
  .pw-level {
    background: grey;
    display: inline-block;
    width: 24%;
    height: 5px;
    float: left;
    &.filled {
      &.level-1 {
        background: #dc3545;
      }
      &.level-2 {
        background: #ffc107;
      }
      &.level-3 {
        background: lightgreen;
      }
      &.level-4 {
        background: lighten(green, 10%);
      }
    }
    &:after {
      content: ' ';
      width: 1%;
      display: inline-block;
      // float: left;
    }
    &:not(:first-child) {
      margin-left: 1%;
    }
    // &:first-child {
    //   border-top-left-radius: 4px;
    //   border-bottom-left-radius: 4px;
    // }
    // &:last-child {
    //   border-top-right-radius: 4px;
    //   border-bottom-right-radius: 4px;
    // }
  }
  .pw-text {
    clear: both;
    text-align: center;
    width: 100%;
  }
}
