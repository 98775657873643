#caseGridContainer {
  th {
    .dropdown-menu {
      position: fixed;
      top: 130px;
      left: 7px;
      background-color: lighten($poop, 40%);
      width: 80px;
      min-width: 80px;

      li {
        text-align: center;
      }
    }
  }

  [dropdown] {
    position: absolute;

    .glyphicon-triangle-bottom {
      margin-left: 3px;
    }
  }
}

.dropdown-input {
  position: relative;
  & > i {
    background: transparent;
    position: absolute;
    top: 0;
    right: 0;
    width: 20px !important;
    height: 20px;
    line-height: 20px;
    text-align: center;
    display: inline;
  }
  & > input {
    padding-right: 20px;
  }
  .glyphicon-list {
    width: 15px;
  }
}

.dropdown-menu.left {
  left: 0;
  top: 100%;
}

.dropdown-toggle {
  cursor: pointer;
}