.left-spacing {
  padding-left: 10px;
}

.right-spacing {
  padding-right: 10px;
}

.cursor-default {
  cursor: default;
}

.cursor-pointer {
  cursor: pointer;
}

.font-14 {
  font-size: 14px;
}

.underline {
  text-decoration: underline;
}

.inline-block {
  display: inline-block;
}

.padding-none {
  padding: 0 !important;
}

.panel {
  margin-bottom: 0;
}

#loading-bar {
  .bar {
    height: 6px;
    background: $green;
  }
}

.disabled-section {
  position: relative;
}

.disabled-cover {
  position: absolute;
  cursor: not-allowed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: black;
  opacity: 0.1;
  z-index: 999;
}

.disabled-color {
  color: $disabledColor !important;
}

.label-light {
  background-color: #e5e5e5;
  border-radius: 4px;
  padding: 0 5px;
}

.padding-right-none {
  padding-right: 0 !important;
}