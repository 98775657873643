.multicases-table {

  &.scrollArea {
    border: none;
  }

  height: 220px !important;
  overflow: auto;

  tr {
    th:first-child, td:first-child {
      border-left: 0;
    }
    th:last-child, td:last-child {
      border-right: 0;
    }
  }

  thead {
    th {
      padding-left: 3px !important;
    }
    th:not(:first-child) {
      border-left: 1px solid #ddd;
    }
  }
  tbody {
    tr:nth-child(even) {
      background-color: lighten($poop, 40%);
    }
    tr:nth-child(odd) {
      background-color: rgb(255, 255, 255);
    }
  }

}

.max500 {
  max-width: 500px;
}
