#header-form {
  position: absolute;
  bottom: 10px;
  left: 135px;

  .header-table {
    height: initial;
  }
  td {
    button {
      width: 100%;
    }
  }
}
